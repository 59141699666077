import { Link } from 'gatsby';
import React from 'react';
import Corner from 'svg/corner.inline.svg';
import QuotesIcon from 'svg/quotes.inline.svg';

import styles from './customer-quote.module.scss';

const CustomerQuote = ({ text, name, userinfo, avatar, color, showQuoteIcon = true, classNames = '', link = null }) => (
  <div className={classNames}>
    <div className={styles.itemWrapper}>
      <div className={`${styles.itemInner} ${styles[color]}`}>
        <div className={styles.itemText}>
          {!color && showQuoteIcon && <QuotesIcon className={styles.itemIcon} />}
          <p>{text}</p>
        </div>
        <div className={`${styles.itemFooter} ${styles[color]} ${avatar ? styles.itemFooterWithAvatar : ''}`}>
          {avatar && (
            <img className={styles.itemAuthorAvatar} src={avatar} width="36" height="36" loading="lazy" alt="" />
          )}
          <div className={`${styles.itemAuthorDetails} ${avatar ? styles.itemAuthorDetailsWithAvatar : ''}`}>
            <h3 className={styles.itemAuthorName}>{name}&nbsp;</h3>
            <span className={styles.itemAuthorUserinfo}>
              {userinfo}
              {link && (
                <>
                  {'. '}
                  <Link to={link.url} className={styles.link}>
                    {link.text}
                  </Link>
                </>
              )}
            </span>
          </div>
        </div>
        {!color && <Corner className={styles.itemCorner} />}
      </div>
    </div>
  </div>
);

export default CustomerQuote;
