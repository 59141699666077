/* eslint-disable max-len */
export const LINKEDIN_REVIEWS = [
  {
    text: `<p>K6 - Like unit testing, for performance 💡💡💡</p>
    <p>k6 is a modern load testing tool, building on Load Impact's years of experience in the load and performance testing industry. It provides a clean, approachable scripting API, local and cloud execution, and flexible configuration.</p>
    <p>✅The k6 API and CLI are easy-to-use, flexible and powerful.
    <br/>✅Write tests in Javascript that mimic real-world scenarios.
    <br/>✅Automate your tests to ensure that your infrastructure and application remain performant.
    <br/>✅Configure SLOs in your k6 script to test the health of your services.
    <br/>✅Create tests faster with our browser recorder and converters (JMeter, Postman, Swagger).</p>
    <p>📌 Link <a href="https://k6.io/" target="_blank">https://k6.io/</a>
    <br/>📌 Github <a href="https://github.com/k6io/k6" target="_blank">https://github.com/k6io/k6</a></p>`,
    author: {
      name: 'Abhishek Choudhary',
      bio: 'Building Data Engineering & Machine Learning Systems for Healthcare 💊 Pharmaceutical 🧪Chemical',
      avatar:
        'https://media-exp1.licdn.com/dms/image/C4E03AQEr2L1tu5A1-w/profile-displayphoto-shrink_800_800/0/1599916770106?e=1634169600&v=beta&t=QJrucI5dpBVrmBgLPwVK16ft5wX1Zo1rsj9vCi7NM4Q',
    },
    postUrl:
      'https://www.linkedin.com/posts/iamabhishekchoudhary_dataengineering-softwareengineering-softwaretesting-activity-6738375489766907904-8nrZ',
  },
  {
    text: `<p><a href="https://k6.io" target="_blank">k6</a> - Load Testing Tool</p>
      <p>I had been assigned the task of running Load Tests on a website. I know that JMeter is available in the market and I have used it before, but I still did a quick search on google on how I can run Load Tests on a web app.</p>
      <p>I came across k6 product and fell in love with it (yes, love at first sight exists). It enables you to write tests in JS while providing you with the ability to simulate a real-world case scenario. Not only this, but it also enables you to set a performance threshold based on percentile, for example, you can set an expectation that you want 99% of the requests to complete within 5 seconds.</p>
      <p>Have I mentioned that they have wonderful documentation? Yes, their documentation is easy to follow and navigate, check it out via https://k6.io/docs</p>
      <p>And you don't have to install k6, you can use Docker to try it out!</p>`,
    author: {
      name: 'Harith Bakhrani',
      bio: 'Backend and DevOps Engineer at Easy Solar',
      avatar:
        'https://media-exp1.licdn.com/dms/image/C4D03AQHqUk9VXp3QZQ/profile-displayphoto-shrink_800_800/0/1535052018455?e=1634169600&v=beta&t=WMm7LyxfX9mdx6Ks-eONTZAZkqdhJHNuSMhe-7TGTeg',
    },
    postUrl:
      'https://www.linkedin.com/posts/harith-bakhrani-26a9b5118_k6-documentation-activity-6773510364027076608-bBqw',
  },
  {
    text: `<p>Loving K6! 🤩</p>
      <p>Devs... a simple performance tester for your APIs.</p>
      <p><a href="https://k6.io/" target="_blank">https://k6.io/</a></p>
      <p>#performancetesting #k6 #opensource #developerlife`,
    author: {
      name: 'Nayan Choudhary',
      bio: 'Technology Enthusiast, Architect, Coach, Agile Modeler',
      avatar:
        'https://media-exp1.licdn.com/dms/image/C4E03AQHPx44v2De0Uw/profile-displayphoto-shrink_800_800/0/1595270502629?e=1652918400&v=beta&t=vxBPp4ydwkVjmiIzyzqGyzNQrvzvPREDqSIeMqtrd3o',
    },
    postUrl:
      'https://www.linkedin.com/posts/nayanchoudhary_load-testing-for-engineering-teams-grafana-activity-6900303376996003840-uuVy/',
  },
  {
    text: `<p>Today I discovered that K6 is an open-source load testing library. It is easy to install and use. It is also available as a SaaS in the cloud.</p>
      <p>It looks pretty cool to me. Maybe I will be exploring it or using it in future or not, but still I though I'll share it with my friends on LinkedIn. Just to brighten up your day.</p>
      <p>Feel free to share insights about K6 and other load testing tools. Would love to know more.</p>`,
    author: {
      name: 'Naeem Malik',
      bio: 'Test Engineer at TransPerfect',
      avatar:
        'https://media-exp1.licdn.com/dms/image/C5603AQEj8hua9OdOQA/profile-displayphoto-shrink_800_800/0/1517656697814?e=1634169600&v=beta&t=JnFzb6DCguT7ZpqWuKFA5Egb33u53uEyHhf1whpl2-w',
    },
    postUrl:
      'https://www.linkedin.com/posts/naeemakrammalik_testautomation-softwaretesting-loadtesting-activity-6790623195029409792-IDH3',
  },
];
