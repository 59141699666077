import { SEO } from 'components/blocks/seo';
import { CustomerQuotes } from 'components/pages/testimonials/customer-quotes';
import { G2Reviews } from 'components/pages/testimonials/g2-reviews';
import { Hero } from 'components/pages/testimonials/hero';
import { Linkedin } from 'components/pages/testimonials/linkedin';
import { Tweets } from 'components/pages/testimonials/tweets';
import { Button } from 'components/shared/button';
import CaseStudies from 'components/shared/case-studies';
import { CTA } from 'components/shared/cta';
import { Thoughtworks } from 'components/shared/thoughtworks';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const Testimonials = () => (
  <DefaultLayout>
    <Hero />
    <CaseStudies page="testimonials" cases={['carvana', 'fubotv', 'olo']} />
    <Thoughtworks />
    <Tweets />
    <CustomerQuotes />
    <Linkedin />
    <G2Reviews />

    <CTA
      title="Ready to get started?"
      description={'Engineering teams of all sizes choose k6 to proactively improve reliability'}
      mode="centered"
    >
      <Button tag={'link'} size={'md'} href={'https://grafana.com/docs/k6/latest/'} cursor>
        Open Source
      </Button>
      <Button
        tag={'a'}
        size={'md'}
        theme={'additional'}
        href={'https://grafana.com/products/cloud/k6/?src=k6io'}
        cursor
      >
        Grafana Cloud k6
      </Button>
    </CTA>
  </DefaultLayout>
);

export default Testimonials;

export const Head = () => <SEO {...SEO_DATA.testimonials} />;
