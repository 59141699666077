import classNames from 'classnames';
import { Button } from 'components/shared/button';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import AverageStars from 'svg/average-stars.inline.svg';
import Corner from 'svg/corner.inline.svg';
import G2Icon from 'svg/g2.inline.svg';
import StarIcon from 'svg/star.inline.svg';

import styles from './g2-reviews.module.scss';

const Stars = ({ rating }) => (
  <div className={styles.starsWrapper}>
    {Array.from(Array(5).keys()).map((item) => (
      <span key={item} className={classNames(styles.star, { [styles.active]: item < rating })}>
        <StarIcon />
      </span>
    ))}
  </div>
);

const getInitials = (name) => {
  const allNames = name.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      return `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
};

export const G2Reviews = () => {
  const {
    allG2Json: { edges: G2REVIEWS },
  } = useStaticQuery(graphql`
    query g2Query {
      allG2Json {
        edges {
          node {
            title
            date
            rating
            text
            author {
              name
              avatar
            }
            reviewURL
          }
        }
      }
    }
  `);

  return (
    <section className={styles.wrapper}>
      <div className={'container'}>
        <div className={styles.allReviews}>
          <div className={styles.allReviewsInner}>
            <G2Icon className={styles.allReviewsIcon} />
            <div className={styles.allReviewsRating}>
              <AverageStars />

              <div className={styles.ratingWrapper}>
                <div className={styles.rating}>Rating: 4.8</div>
                <div className={styles.reviewsTotal}>30 Reviews</div>
              </div>
            </div>
          </div>
          <Button
            tag={'a'}
            className={styles.reviewsButton}
            target={'_blank'}
            href={'https://www.g2.com/products/k6/reviews#reviews'}
          >
            See all reviews
          </Button>
        </div>
        <div className={styles.itemsWrapper}>
          <div className={'row'}>
            {G2REVIEWS.map(
              (
                {
                  node: {
                    title,
                    text,
                    date,
                    rating,
                    author: { name, avatar },
                    reviewURL,
                  },
                },
                index
              ) => (
                <div className={'col-lg-4 col-md-6 col-sm-12'} key={`g2_${index}`}>
                  <div className={styles.itemWrapper}>
                    <a className={styles.item} href={reviewURL} target={'_blank'} rel={'noreferrer'}>
                      <div className={styles.itemInner}>
                        <div className={styles.itemHeader}>
                          <div className={classNames(styles.authorAvatar, !avatar && styles.authorAvatarOpacity)}>
                            {avatar && <img src={avatar} alt={name} />}
                            <span className={styles.authorAvatarInitials}>{!avatar && getInitials(name)}</span>
                          </div>
                          <div className={styles.authorDetails}>
                            <h3 className={styles.authorName}>{name}</h3>
                            <div className={styles.details}>
                              <Stars rating={rating} />
                              <span className={styles.date}>{date}</span>
                            </div>
                          </div>
                        </div>
                        <div className={styles.content}>
                          <p className={styles.itemTitle}>&quot;{title}&quot;</p>
                          {/* eslint-disable-next-line react/no-danger */}
                          <p className={styles.itemText} dangerouslySetInnerHTML={{ __html: text }} />
                        </div>
                        <div className={styles.footer}>
                          <span className={styles.link}>Read more</span>
                          <div className={styles.itemIconWrapper}>
                            <G2Icon className={styles.itemIcon} />
                          </div>
                        </div>
                        <Corner className={styles.corner} />
                      </div>
                    </a>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
