import CustomerQuote from 'components/shared/customer-quote/customer-quote';
import { Heading } from 'components/shared/heading';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import styles from './customer-quotes.module.scss';

export const CustomerQuotes = () => {
  const {
    allCustomerQuotesJson: { edges: DATA },
  } = useStaticQuery(graphql`
    query testimonials {
      allCustomerQuotesJson {
        edges {
          node {
            text
            author {
              name
              userinfo
            }
          }
        }
      }
    }
  `);

  return (
    <section className={styles.wrapper}>
      <div className={'container'}>
        <Heading className={styles.title} tag={'h2'}>
          Customer Quotes
        </Heading>
        <div className={styles.itemsWrapper}>
          <div className={'row'}>
            {DATA.map(
              (
                {
                  node: {
                    text,
                    author: { name, userinfo },
                  },
                },
                index
              ) => {
                const position = (index + 1) % 4;
                let classNames = 'col-lg-7 col-md-12 col-sm-12';
                let color = false;
                if (position === 2 || position === 3) {
                  classNames = 'col-lg-5 col-md-6 col-sm-12';
                  color = 'gray';
                }
                return (
                  <CustomerQuote
                    classNames={classNames}
                    key={`feedbacks_${index}`}
                    color={color}
                    name={name}
                    text={text}
                    userinfo={userinfo}
                  />
                );
              }
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
