import classNames from 'classnames';
import { Heading } from 'components/shared/heading';
import { ItemCard } from 'components/shared/item-card';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import styles from './case-studies.module.scss';

const CASE_CUSTOMERS = ['carvana', 'olo', 'thoughtworks'];

const CASE_STUDIES = {
  distributed: {
    category: 'Tutorial',
    title: 'Running distributed k6 tests on Kubernetes',
    href: 'https://k6.io/blog/running-distributed-tests-on-k8s/',
    isExternal: true,
  },
  solutionSheet: {
    category: 'executive solution sheet',
    title: 'Why Your Organization Should Perform Load Testing',
    link: '/why-your-organization-should-perform-load-testing/',
  },
  whitePaper: {
    category: 'cto white paper',
    title: 'What to Consider When Building or Buying a Load Testing Solution',
    href: 'https://grafana.com/blog/2024/01/30/load-testing-tools-open-source-considerations-for-homegrown-solutions/',
    isExternal: true,
  },
  ossVsCloud: {
    category: 'Product Comparison',
    title: 'Comparing k6 Open Source and k6 Cloud',
    link: '/oss-vs-cloud/',
  },
  k6CloudUnderTheHood: {
    category: 'engineering blog',
    title: 'Peeking under the hood of a k6 Cloud test',
    href: 'https://k6.io/blog/the-glorious-backend/',
    isExternal: true,
  },
  k6CloudUnderTheHood2: {
    category: 'engineering blog',
    title: 'Peeking under the hood of a k6 Cloud test',
    href: 'https://k6.io/blog/the-glorious-backend/',
    isExternal: true,
  },
  collaborativeWhitePaper: {
    category: 'cto white paper',
    title: 'Modern Load Testing for Engineering Teams',
    link: '/modern-load-testing-for-engineering-teams/',
  },
  manifesto: {
    category: 'Manifesto',
    title: 'Developer-centric load testing Manifesto',
    link: '/our-beliefs/',
  },
  carvana: {
    category: 'customer case study',
    title: 'Carvana Speeds Up Test Creation by 100% with k6 Cloud Enterprise',
    href: 'https://grafana.com/success/k6-carvana/',
    isExternal: true,
  },
  fubotv: {
    category: 'customer case study',
    title: 'fuboTV Tackles Performance Regressions Before Production',
    href: 'https://grafana.com/success/k6-fubotv/',
    isExternal: true,
  },
  carvana2: {
    category: 'customer case study',
    title: '50% of tests are now done outside of the QA team',
    href: 'https://grafana.com/success/k6-carvana/',
    isExternal: true,
  },
  olo: {
    category: 'customer case study',
    title: 'Olo Delivers Faster Testing and Improved Collaboration with k6',
    href: 'https://grafana.com/success/k6-olo/',
    isExternal: true,
  },
  thoughtworks: {
    category: 'industry report',
    title: 'k6 in ThoughtWorks Technology Radar',
    href: 'https://www.thoughtworks.com/en-us/radar/tools/k6',
    isExternal: true,
  },
};

export const CaseStudyCard = ({ caseData }) => (
  <ItemCard to={caseData.link} href={caseData.href} isExternal={caseData.isExternal} label={styles.card}>
    <div className={styles.content}>
      <GatsbyImage image={caseData.image} style={{ height: '100%' }} alt={caseData.title} aspectRatio={3 / 1} />
      <p className={styles.category}>{caseData.category}</p>
      <Heading className={styles.title} tag={'h3'} size={'md'}>
        {caseData.title}
      </Heading>
    </div>
    <div className={styles.footer}>
      <div className={styles.link}>{caseData.footer ? caseData.footer : 'Read more'}</div>
    </div>
  </ItemCard>
);

const CaseStudies = ({ title, page = 'testimonials', cases = CASE_CUSTOMERS, casesData = CASE_STUDIES }) => {
  const {
    carvana,
    distributed,
    olo,
    fubotv,
    manifesto,
    thoughtworks,
    solutionSheet,
    ossVsCloud,
    whitePaper,
    k6CloudUnderTheHood,
    k6CloudUnderTheHood2,
    collaborativeWhitePaper,
  } = useStaticQuery(graphql`
    query caseStudyImages {
      carvana: file(absolutePath: { regex: "/carvana-hero@2x.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      distributed: file(absolutePath: { regex: "/oss-vs-cloud/distributed.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      olo: file(absolutePath: { regex: "/olo-delivery-hero.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      k6CloudUnderTheHood: file(absolutePath: { regex: "/white-paper.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      k6CloudUnderTheHood2: file(absolutePath: { regex: "/oss-vs-cloud/distributed.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      fubotv: file(absolutePath: { regex: "/fubotv-hero.jpeg/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      manifesto: file(absolutePath: { regex: "/manifesto.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      thoughtworks: file(absolutePath: { regex: "/thoughtworks-02.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      whitePaper: file(absolutePath: { regex: "/resources/white-paper.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      ossVsCloud: file(absolutePath: { regex: "/resources/oss-vs-cloud.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      collaborativeWhitePaper: file(absolutePath: { regex: "/resources/collaborative-whitepaper.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
      solutionSheet: file(absolutePath: { regex: "/resources/solution-sheet.png/" }) {
        childImageSharp {
          gatsbyImageData(width: 535)
        }
      }
    }
  `);

  CASE_STUDIES.carvana.image = getImage(carvana);
  CASE_STUDIES.carvana2.image = getImage(carvana);
  CASE_STUDIES.olo.image = getImage(olo);
  CASE_STUDIES.fubotv.image = getImage(fubotv);
  CASE_STUDIES.manifesto.image = getImage(manifesto);
  CASE_STUDIES.thoughtworks.image = getImage(thoughtworks);
  CASE_STUDIES.whitePaper.image = getImage(whitePaper);
  CASE_STUDIES.collaborativeWhitePaper.image = getImage(collaborativeWhitePaper);
  CASE_STUDIES.solutionSheet.image = getImage(solutionSheet);
  CASE_STUDIES.ossVsCloud.image = getImage(ossVsCloud);
  CASE_STUDIES.k6CloudUnderTheHood.image = getImage(k6CloudUnderTheHood);
  CASE_STUDIES.k6CloudUnderTheHood2.image = getImage(k6CloudUnderTheHood2);
  CASE_STUDIES.distributed.image = getImage(distributed);

  return (
    <div className={'container'}>
      {title && (
        <Heading tag="h2" className={styles.sectionTitle}>
          {title}
        </Heading>
      )}
      <div className={classNames(styles.list, { [styles.listTestimonials]: page === 'testimonials' })}>
        {cases.map((customer) => (
          <CaseStudyCard caseData={casesData[customer]} key={customer} />
        ))}
      </div>
    </div>
  );
};

export default CaseStudies;
