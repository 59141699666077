import { PageInfo } from 'components/shared/page-info';
import React from 'react';

import styles from './testimonials-hero.module.scss';

export const Hero = () => (
  <PageInfo
    className={styles.wrapper}
    title={'Case Studies & Testimonials'}
    description={'Thousands of teams and developers use k6 every day. Read their use cases and stories.'}
    withBgPattern
  />
);
