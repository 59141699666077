import { Heading } from 'components/shared/heading';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Corner from 'svg/corner.inline.svg';
import TwitterIcon from 'svg/twitter.inline.svg';

import styles from './tweets.module.scss';

export const Tweets = () => {
  const {
    allTweetsJson: { edges: TWEETS },
  } = useStaticQuery(graphql`
    query tweetQuery {
      allTweetsJson {
        edges {
          node {
            text
            author {
              name
              username
              avatar
            }
            tweetURL
          }
        }
      }
    }
  `);

  return (
    <section className={styles.wrapper}>
      <div className={'container'}>
        <Heading className={styles.title} tag={'h2'}>
          Tweets
        </Heading>
        <div className={styles.itemsWrapper}>
          <div className={'row'}>
            {TWEETS.map(
              (
                {
                  node: {
                    text,
                    author: { name, username, avatar },
                    tweetURL,
                  },
                },
                index
              ) => (
                <div className={'col-lg-4 col-md-6 col-sm-12'} key={`tweets_${index}`}>
                  <div className={styles.itemWrapper}>
                    <a className={styles.item} href={tweetURL} target={'_blank'} rel={'noreferrer'} key={tweetURL}>
                      <div className={styles.itemInner}>
                        <div className={styles.itemIconWrapper}>
                          <TwitterIcon className={styles.itemIcon} />
                        </div>
                        {/* eslint-disable-next-line react/no-danger */}
                        <p className={styles.itemText} dangerouslySetInnerHTML={{ __html: text }} />
                        <div className={styles.itemFooter}>
                          <img className={styles.itemAuthorAvatar} src={avatar} alt={name} />
                          <div className={styles.itemAuthorDetails}>
                            <h3 className={styles.itemAuthorName}>{name}</h3>
                            <span className={styles.itemAuthorUsername}>{username}</span>
                          </div>
                        </div>
                        <Corner className={styles.itemCorner} />
                      </div>
                    </a>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
