import { Heading } from 'components/shared/heading';
import React from 'react';
import Corner from 'svg/corner.inline.svg';

import Arrow from './svg/arrow.inline.svg';
import ThoughtWorksLogo from './svg/thoughtworks-logo.inline.svg';
import styles from './thoughtworks.module.scss';

const TECH_RADAR_URL = 'https://www.thoughtworks.com/en-us/radar/tools/k6';

export const Thoughtworks = () => (
  <section className={styles.wrapper}>
    <a className={styles.item} href={TECH_RADAR_URL} target={'_blank'} rel={'noreferrer'}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <Heading className={styles.title} tag={'h3'}>
            k6 included in tech radar
          </Heading>
          <ThoughtWorksLogo className={styles.logo} />
        </div>
        <div className={styles.arrow}>
          <Arrow />
        </div>
        <Corner className={styles.itemCorner} />
      </div>
    </a>
  </section>
);
