import classNames from 'classnames';
import { Heading } from 'components/shared/heading';
import React from 'react';
import Corner from 'svg/corner.inline.svg';
import LinkedinIcon from 'svg/linkedin.inline.svg';

import { LINKEDIN_REVIEWS } from './data/linkedin-reviews';
import styles from './linkedin.module.scss';

export const Linkedin = () => (
  <section className={styles.wrapper}>
    <div className={'container'}>
      <Heading className={styles.title} tag={'h2'}>
        LinkedIn
      </Heading>
      {LINKEDIN_REVIEWS.map(({ text, author: { name, bio }, postUrl }, index) => (
        <a className={styles.item} href={postUrl} target={'_blank'} rel={'noreferrer'} key={postUrl}>
          <div className={classNames(styles.info, index % 2 === 1 && styles.right)}>
            <div className={styles.author}>
              {/* <img className={styles.authorAvatar} src={avatar} alt={name} /> */}
              <div className={styles.authorDetails}>
                <h3 className={styles.authorName}>{name}</h3>
                <span className={styles.authorBio}>{bio}</span>
              </div>
            </div>
            <div className={styles.iconWrapper}>
              <LinkedinIcon className={styles.icon} />
            </div>
          </div>
          <div className={styles.itemInner}>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={styles.itemText} dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          <Corner className={styles.itemCorner} />
        </a>
      ))}
    </div>
  </section>
);
